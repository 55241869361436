const getSiteParams = () => {
  switch (window.location.hostname) {
    case 'rozee-beta.rozeegpt.ai':
      return { url: 'https://beta-api.rozeegpt.ai/rest/api/', siteAt: 'beta' };
      break;
    case 'beta.rozeegpt.ai':
      return { url: 'https://beta-api.rozeegpt.ai/rest/api/', siteAt: 'beta' };
      break;
    case 'demo.rozeegpt.ai':
      return { url: 'https://demo-api.rozeegpt.ai/rest/api/', siteAt: 'demo' };
      break;
    case 'betaqa.rozeegpt.ai':
      return {
        url: 'https://betaqa-api.rozeegpt.ai/rest/api/',
        siteAt: 'betaqa',
      };
      break;
    case 'demoqa.rozeegpt.ai':
      return {
        url: 'https://demoqa-api.rozeegpt.ai/rest/api/',
        siteAt: 'demoqa',
      };
      break;
    case 'test.rozeegpt.ai':
      return { url: 'https://test-api.rozeegpt.ai/rest/api/', siteAt: 'test' };
      break;
    case 'rozeegpt.ai':
      return { url: 'https://api.rozeegpt.ai/rest/api/', siteAt: 'live' };
      break;
    case 'www.rozeegpt.ai':
      return { url: 'https://api.rozeegpt.ai/rest/api/', siteAt: 'live' };
      break;
    default:
      const PORT = window.location.port;
      if (PORT === '3001') {
        return {
          url: 'http://sailfish.naseeb.local/dev/hammad-siddiqui/rozeegpt/rest/api/',
          siteAt: 'sailfish',
        };
      } else if (PORT === '3002') {
        return {
          url: 'http://sailfish.naseeb.local/dev/nimrahaider/rozeegpt/rest/api/',
          siteAt: 'sailfish',
        };
      } else {
        return {
          url: 'http://sailfish.naseeb.local/dev/hammad-siddiqui/rozeegpt/rest/api/',
          siteAt: 'sailfish',
        };
      }
  }
};

export const SITE_AT = getSiteParams()?.siteAt;
export const SITE_URL = window.location.host;
export const API_BASE_URL = getSiteParams()?.url;
