import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Dialog, Stack, Typography } from '@mui/material';

import Style from './style';

const Styles = makeStyles((theme) => Style(theme));

export function OfflineDialog() {
  const classes = Styles();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Dialog open={!isOnline} className={classes.main__dialog__success}>
      <Stack
        p={2}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <Stack alignItems='center' justifyContent='center'>
          <WifiOffIcon
            style={{ cursor: 'pointer', height: '70px', width: '70px' }}
            onClick={() => setIsOnline(() => false)}
          />
        </Stack>
        <Typography pt={2} sx={{ fontWeight: '600' }} variant={'title20Bold'}>
          You are currently offline.
        </Typography>
        <Typography
          variant='title14Bold'
          sx={{ textAlign: 'center', fontWeight: '400' }}
          pt={1.5}
        >
          You are offline.
        </Typography>
      </Stack>
    </Dialog>
  );
}
