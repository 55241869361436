import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useGAuth = () => {
    const [error, setError] = useState(null);
    const [isAuthenticatingWithGoogle, setIsAuthenticatingWithGoogle] = useState(null);
    const [response, setResponse] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const verifyGAuth = async (data) => {
        setIsAuthenticatingWithGoogle(true);

        const resp = await usefetch('career/login', 'POST', defaultHeaders(), data);

        if (resp?.code === '11') {
            // setIsCompleted(true);
        }

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsAuthenticatingWithGoogle(false);
        return resp;
    };

    return { verifyGAuth, response,  isAuthenticatingWithGoogle, error };
};