import React from 'react';
import { useTheme } from '@mui/styles';
import { TypeAnimation } from 'react-type-animation';
import { Stack, useMediaQuery, Typography } from '@mui/material';
import { LinearProgress, Box } from '@mui/material';

import LoaderGif from '../../assets/images/loader-gif.gif';
import { LogoWhite } from '../../assets/constants';

export default function Loader({
  seekerCvUpload,
  textWithLoader,
  text1,
  text2,
  text3,
  progressBar,
  progress,
  uploadSpeed,
}) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Stack
      sx={{
        position: 'absolute',
        left: '0',
        top: '0',
        height: '100dvh',
        width: '100vw',
        background: seekerCvUpload ? 'rgba(0,0,0,0.8)' : '#000',
        margin: '0 !important',
        zIndex: 2000,
      }}
      alignItems='center'
      justifyContent='center'
      className='loader__common'
    >
      <Stack
        p={3}
        sx={{
          borderRadius: '40px',
          border: seekerCvUpload ? 'none' : '1px solid #000',
          background: seekerCvUpload ? 'none' : '#000',
          boxShadow: 'none',
        }}
      >
        <LogoWhite />

        <img
          style={{ marginTop: '5rem' }}
          height='200px'
          width='200px'
          src={LoaderGif}
          alt=''
        />

        {/* Progress bar with percentage */}
        {progressBar && (
          <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
            <LinearProgress
              variant='determinate'
              value={progress}
              sx={{
                height: 5,
                backgroundColor: '#313131',
                borderRadius: '14px',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#fff',
                  borderRadius: '14px',
                },
              }}
            />
            <Typography variant='body1' sx={{ mt: 2, color: '#fff' }}>
              {progress}% uploaded{' '}
              {uploadSpeed &&
                `... Speed: ${uploadSpeed && uploadSpeed.toFixed(2)} KB/s`}
            </Typography>
          </Box>
        )}
      </Stack>

      {textWithLoader && (
        <Stack px={2} sx={{ color: seekerCvUpload ? '#fff' : '#fff' }}>
          <TypeAnimation
            sequence={[text1, 2000, text2, 2000, text3, 2000]}
            style={{
              fontSize: smMediaquery ? '16px' : '26px',
              fontWeight: '600',
              lineHeight: smMediaquery ? '28px' : '36px',
              textAlign: smMediaquery ? 'center' : 'left',
            }}
            repeat={Infinity}
          />
        </Stack>
      )}
    </Stack>
  );
}
