import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loader from '../common/Loader';
import ScrollToTop from './ScrollToTop';
import PersistLogin from '../Auth/PresistLogin';
import { OfflineDialog } from '../components/common/OfflineDialog';

import { GuestRoute } from './GuestRoutes';
import { AuthProvider } from '../Auth/AuthProvider';

import NoPage from '../pages/NoPage';
const Signin = lazy(() => import('../pages/Signin'));
const Signup = lazy(() => import('../pages/Signup'));

const userToken = localStorage.getItem('token');

const MainRouts = () => (
  <AuthProvider>
    <Suspense fallback={<Loader />}>
      <ScrollToTop />
      <OfflineDialog />
      <Routes>
        <Route element={<PersistLogin />}>
          <Route element={<GuestRoute />}>
            <Route path='/' element={<Signup />} />
            <Route path='/signin' element={<Signin />} />
            <Route path='*' element={<NoPage />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  </AuthProvider>
);
export default MainRouts;
