import { isEmpty } from './common';
import { API_BASE_URL, SITE_URL } from './utils';

export const usefetch = async (url, method, headers, data = {}) => {
  const response = await fetch(API_BASE_URL + url, {
    method: method,
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response?.code === '421') {
        sessionStorage.clear();
        localStorage.clear();
        const careerCoachInterview = localStorage.getItem(
          'careerCoachInterview',
        );
        if (isEmpty(careerCoachInterview)) {
          window.location.href = `${SITE_URL}/login`;
        } else {
          window.location.href = `${SITE_URL}/login`;
        }
        window.location.reload(); // in case user is already on the signup page
      }
      return response;
    })
    .catch((e) => {
      console.log(e);
      return { code: '00', message: 'Something went wrong' };
    });
  return response;
};

export const usefileDownloader = async (url, method, headers, data = {}) => {
  const response = await fetch(API_BASE_URL + url, {
    method: method,
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((response) => response)
    .then((response) => {
      let file = new Blob([response], { type: 'application/pdf' });
      let fileURl = window.URL.createObjectURL(file);
      window.open(fileURl);
      // window.location.assign(file);
    })
    .catch((e) => {
      return e;
    });
};

// export const fileUploader = (formData) => fetch('http://sailfish.naseeb.local/dev/nimrahaider/rozeegpt/rest/api/cv/uploadcvs', {method: 'POST', body: formData})
//     .then((response) => response.json())
//     .then(function (response) {
//       if(response.data != null) {
//         tempArray.push(response.data);
//       }
//     });

export const usefileUploader = async (url, method, headers, data = {}) => {
  const response = await fetch(API_BASE_URL + url, {
    method: method,
    headers: headers,
    body: data,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response?.code === '421') {
        sessionStorage.clear();
        localStorage.clear();
        const careerCoachInterview = localStorage.getItem(
          'careerCoachInterview',
        );
        if (isEmpty(careerCoachInterview)) {
          window.location.href = `${SITE_URL}/login`;
        } else {
          window.location.href = `${SITE_URL}/login`;
        }
        window.location.reload(); // in case user is already on the signup page
      }
      return response;
    })
    .catch((e) => {
      return e;
    });
  return response;
};

export const headers = () => {
  let token = localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    token: token,
  };
};

export const defaultHeaders = () => {
  let token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    appId: 5,
    appKey: '0b4e7a0e5fe84ad35fb5f95b9ceeac79',
  };
  // Add the token if available
  if (token) {
    headers.token = token;
  }

  return headers;
};

export const fileUploadTokenHeaders = () => {
  let token = localStorage.getItem('token');
  return {
    token: token,
  };
};

export const fileUploadHeaders = () => {
  return {
    appId: 5,
    appKey: '0b4e7a0e5fe84ad35fb5f95b9ceeac79',
  };
};
