import React from 'react';
import { useTheme } from '@mui/styles';
import { TypeAnimation } from 'react-type-animation';
import { Stack, useMediaQuery } from '@mui/material';

import { LogoWhite } from '../../assets/constants';
import LoaderGif from '../../assets/images/loader-gif.gif';

export default function MainLoader({ globalLoader, loaderTexts }) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {globalLoader && (
        <Stack
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100vh',
            width: '100vw',
            background: '#000',
            margin: '0 !important',
            zIndex: 2000,
          }}
          alignItems='center'
          justifyContent='center'
          className='loader__common'
        >
          <Stack
            p={3}
            sx={{
              borderRadius: '40px',
              border: '1px solid #000',
              background: '#000',
              boxShadow: 'none',
              // backdropFilter: 'blur(17px)',
            }}
          >
            <LogoWhite />
            <img
              style={{ marginTop: '5rem' }}
              height='200px'
              width='200px'
              src={LoaderGif}
              alt=''
            />
          </Stack>
          {loaderTexts?.text1 && (
            <Stack px={2} sx={{ color: '#fff' }}>
              <TypeAnimation
                sequence={[
                  loaderTexts?.text1,
                  2000,
                  loaderTexts?.text2,
                  2000,
                  loaderTexts?.text3,
                  2000,
                ]}
                style={{
                  fontSize: smMediaquery ? '16px' : '26px',
                  fontWeight: '600',
                  lineHeight: smMediaquery ? '28px' : '36px',
                  textAlign: smMediaquery ? 'center' : 'left',
                }}
                repeat={Infinity}
              />
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
}
