import React, { createContext, useContext, useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import MainRoutes from './routes';
// import store from './store/index';

import theme from './theme/theme';

import './assets/scss/index.scss';
import SnackbarCustom from './common/Snackbar';
import { AuthProvider } from './Auth/AuthProvider';
import { createCookie } from './helpers/CookieHelper';
import { SITE_AT } from './helpers/utils';
import MainLoader from './common/Loader/MainLoader';

const tagManagerArgs = {
  gtmId: 'GTM-PXRQCJJD',
  // dataLayer: {
  //   userProject: 'project',
  // },
};

const SnackbarContext = createContext();
export const useSnackbar = () => useContext(SnackbarContext);

const GlobalLoaderContext = createContext();
export const useGlobalLoader = () => useContext(GlobalLoaderContext);

const routingBaseUrl = process.env.REACT_APP_ROUTING_BASE_URL;
const App = () => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [globalLoader, setGlobalLoader] = useState(false);

  useEffect(() => {
    if (SITE_AT === 'beta' || SITE_AT === 'sailfish') {
      createCookie('flow', 'new');
    }
  }, []);

  TagManager.initialize(tagManagerArgs);
  return (
    <BrowserRouter basename={routingBaseUrl}>
      <ThemeProvider theme={theme}>
        <SnackbarContext.Provider value={{ setSnackbarMessage }}>
          <GlobalLoaderContext.Provider
            value={{ globalLoader, setGlobalLoader }}
          >
            <AuthProvider>
              <MainRoutes />
              <MainLoader
                globalLoader={globalLoader}
                setGlobalLoader={setGlobalLoader}
              />
              <SnackbarCustom
                response={snackbarMessage}
                handleClose={() => {
                  setSnackbarMessage('');
                }}
              />
            </AuthProvider>
          </GlobalLoaderContext.Provider>
        </SnackbarContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
